@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@charset "UTF-8";

:root {
  --blue: #324daa;
  --blue-rgb: 32, 188, 239;
  --orange: #41c9d6;
  --orange-rgb: 241, 88, 41;
  --green: #08cf08;
  --grey: #edf2f9;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  min-height: 100vh;
  overflow-y: scroll;
}

body.loading-data {
  pointer-events: none;
}

body.loading-data::before {
  content: '';
  position: fixed;
  left: 0;
  background: linear-gradient(to left, #41c9d6, #324daa) !important;
  background: linear-gradient(to left, var(--orange), var(--blue)) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  -webkit-animation: beforeloading 2s infinite ease-out;
  animation: beforeloading 2s infinite ease-out;
}

body.loading-data::after {
  content: '';
  position: fixed;
  left: 0;
  background: linear-gradient(to left, #41c9d6, #324daa) !important;
  background: linear-gradient(to left, var(--orange), var(--blue)) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  -webkit-animation: afterloadding 2s infinite ease-in;
  animation: afterloadding 2s infinite ease-in;
}


@-webkit-keyframes beforeloading {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes beforeloading {
  0% {
    left: -100%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@-webkit-keyframes afterloadding {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

@keyframes afterloadding {
  0% {
    left: -150%;
    width: 100%;
  }

  100% {
    left: 100%;
    width: 10%;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: rgba(32, 188, 239, 0.1);
  background: rgba(var(--blue-rgb), 0.1);
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(32, 188, 239, 0.75);
  background-color: rgba(var(--blue-rgb), 0.75);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #41c9d6;
  background-color: var(--orange);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.l {
  box-shadow: inset 0px 0px 5px red;
}

.bi-2x {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.bi-3x {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.bi-4x {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.bi-5x {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
}

.bi-6x {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.bi-7x {
  -webkit-transform: scale(1.6);
  transform: scale(1.6);
}

.bi-8x {
  -webkit-transform: scale(1.7);
  transform: scale(1.7);
}

.bi-9x {
  -webkit-transform: scale(1.8);
  transform: scale(1.8);
}

.fs-10px {
  font-size: 10px !important;
}

.fs-11px {
  font-size: 11px !important;
}

.fs-12px {
  font-size: 12px !important;
}

.fs-13px {
  font-size: 13px !important;
}

.fs-14px {
  font-size: 14px !important;
}

.fs-15px {
  font-size: 15px !important;
}

.fs-16px {
  font-size: 16px !important;
}

.fs-17px {
  font-size: 17px !important;
}

.fs-18px {
  font-size: 18px !important;
}

.fs-19px {
  font-size: 19px !important;
}

.fs-20px {
  font-size: 20px !important;
}

.fs-21px {
  font-size: 21px !important;
}

.fs-25px {
  font-size: 25px !important;
}

.fs-28px {
  font-size: 28px !important;
}

.fs-30px {
  font-size: 30px !important;
}

.fs-32px {
  font-size: 32px !important;
}

.fs-35px {
  font-size: 35px !important;
}

.fs-50px {
  font-size: 50px !important;
}

.fs-60px {
  font-size: 60px !important;
}

.fs-65px {
  font-size: 65px !important;
}

.fs-68px {
  font-size: 68px !important;
}

.fs-70px {
  font-size: 70px !important;
}

.fs-80px {
  font-size: 80px !important;
}

.fs-90px {
  font-size: 90px !important;
}

.fs-100px {
  font-size: 100px !important;
}

.fs-120px {
  font-size: 120px !important;
}

.fs-150px {
  font-size: 150px !important;
}

.fw-medium {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-family: 'Roboto', sans-serif;
}

.rounded-6 {
  border-radius: 6px;
}

.rounded-8 {
  border-radius: 8px;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-12 {
  border-radius: 12px;
}

.rounded-15 {
  border-radius: 15px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounded-25 {
  border-radius: 25px;
}

.rounded-30 {
  border-radius: 30px;
}

.img-cover {
  object-fit: cover;
  object-position: center;
}

.opacity-10 {
  opacity: 0.10;
}

.opacity-15 {
  opacity: 0.15;
}

.opacity-20 {
  opacity: 0.20;
}

.opacity-30 {
  opacity: 0.30;
}

.opacity-35 {
  opacity: 0.35;
}

.opacity-40 {
  opacity: 0.40;
}

.opacity-45 {
  opacity: 0.45;
}

.opacity-60 {
  opacity: 0.60;
}

.opacity-65 {
  opacity: 0.65;
}

.opacity-70 {
  opacity: 0.70;
}

.opacity-80 {
  opacity: 0.80;
}

.opacity-85 {
  opacity: 0.85;
}

.opacity-90 {
  opacity: 0.90;
}

.ps-5px {
  padding-left: 5px;
}

.ps-10px {
  padding-left: 10px;
}

.ps-15px {
  padding-left: 15px;
}

.ps-16px {
  padding-left: 16px;
}

.ps-17px {
  padding-left: 17px;
}

.ps-18px {
  padding-left: 18px;
}

.py-1px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-3px {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.zindex-0 {
  z-index: 0;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.zindex-4 {
  z-index: 4;
}

.h-2px {
  height: 2px !important;
}

.h-3px {
  height: 3px;
}

.h-4px {
  height: 4px;
}

.h-5px {
  height: 5px;
}

.h-6px {
  height: 6px;
}

.h-8px {
  height: 8px;
}

.h-10px {
  height: 10px;
}

.h-20px {
  height: 20px;
}

.h-25px {
  height: 25px;
}

.h-30px {
  height: 30px;
}

.h-35px {
  height: 35px;
}

.h-40px {
  height: 40px;
}

.h-50px {
  height: 50px;
}

.h-55px {
  height: 55px;
}

.h-58px {
  height: 58px;
}

.h-72px {
  height: 72px;
}

.h-75px {
  height: 75px;
}

.h-80px {
  height: 80px;
}

.w-15px {
  width: 15px;
}

.w-20px {
  width: 20px;
}

.w-22px {
  width: 22px;
}

.w-25px {
  width: 25px;
}

.w-30px {
  width: 30px;
}

.w-35px {
  width: 35px;
}

.w-40px {
  width: 40px;
}

.w-50px {
  width: 50px;
}

.w-60px {
  width: 60px;
}

.w-70px {
  width: 70px;
}

.w-80px {
  width: 80px;
}

.w-90px {
  width: 90px;
}

.w-100px {
  width: 100px;
}

.w-110px {
  width: 110px;
}

.w-120px {
  width: 120px;
}

.mmwh-20px {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
}

.mmwh-22px {
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
}

.mmwh-24px {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
}

.mmwh-26px {
  min-width: 26px;
  max-width: 26px;
  min-height: 26px;
  max-height: 26px;
}

.mmwh-28px {
  min-width: 28px;
  max-width: 28px;
  min-height: 28px;
  max-height: 28px;
}

.mmwh-30px {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
}

.mmwh-32px {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
}

.mmwh-34px {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
}

.mmwh-35px {
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
}

.mmwh-36px {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
}

.mmwh-38px {
  min-width: 38px;
  max-width: 38px;
  min-height: 38px;
  max-height: 38px;
}

.mmwh-40px {
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
}

.mmwh-200px {
  min-width: 200px;
  max-width: 200px !important;
  min-height: 200px;
  max-height: 200px !important;
}

.mmwh-200px img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}


.border-lightblue {
  border: 1px solid #d8f5ff;
}

.mmw-5px {
  min-width: 5px;
  max-width: 5px;
}

.mmw-6px {
  min-width: 6px;
  max-width: 6px;
}

.mmw-7px {
  min-width: 7px;
  max-width: 7px;
}

.mmw-8px {
  min-width: 8px;
  max-width: 8px;
}

.mmw-10px {
  min-width: 10px;
  max-width: 10px;
}

.mmw-20px {
  min-width: 20px;
  max-width: 20px;
}

.mmw-24px {
  min-width: 24px;
  max-width: 24px;
}

.mmw-25px {
  min-width: 25px;
  max-width: 25px;
}

.mmw-30px {
  min-width: 30px;
  max-width: 30px;
}

.mmw-35px {
  min-width: 35px;
  max-width: 35px;
}

.mmw-36px {
  min-width: 36px;
  max-width: 36px;
}

.mmw-40px {
  min-width: 40px;
  max-width: 40px;
}

.mmw-50px {
  min-width: 50px;
  max-width: 50px;
}

.mmw-60px {
  min-width: 60px;
  max-width: 60px;
}

.mmw-70px {
  min-width: 70px;
  max-width: 70px;
}

.mmw-80px {
  min-width: 80px;
  max-width: 80px;
}

.mmw-90px {
  min-width: 90px;
  max-width: 90px;
}

.mmw-100px {
  min-width: 100px;
  max-width: 100px;
}

.mmw-110px {
  min-width: 110px;
  max-width: 110px;
}

.mmw-115px {
  min-width: 115px;
  max-width: 115px;
}

.mmw-120px {
  min-width: 120px;
  max-width: 120px;
}

.mmw-140px {
  min-width: 140px;
  max-width: 140px;
}

.mmw-150px {
  min-width: 150px;
  max-width: 150px;
}

.mmw-155px {
  min-width: 155px;
  max-width: 155px;
}

.mmw-160px {
  min-width: 160px;
  max-width: 160px;
}

.mmw-170px {
  min-width: 170px;
  max-width: 170px;
}

.mmw-180px {
  min-width: 180px;
  max-width: 180px;
}

.mmw-190px {
  min-width: 190px;
  max-width: 190px;
}

.mmw-200px {
  min-width: 200px;
  max-width: 200px;
}

.mmw-210px {
  min-width: 210px;
  max-width: 210px;
}

.mmw-220px {
  min-width: 220px;
  max-width: 220px;
}

.mmw-240px {
  min-width: 240px;
  max-width: 240px;
}

.mmw-260px {
  min-width: 260px;
  max-width: 260px;
}

.mmw-280px {
  min-width: 280px;
  max-width: 280px;
}

.mmw-300px {
  min-width: 300px;
  max-width: 300px;
}

.mmw-320px {
  min-width: 320px;
  max-width: 320px;
}

.minw-80px {
  min-width: 80px;
}

.minw-90px {
  min-width: 90px;
}

.minw-100px {
  min-width: 100px;
}

.minw-110px {
  min-width: 110px;
}

.minw-120px {
  min-width: 120px;
}

.minw-130px {
  min-width: 130px;
}

.minh-70px {
  min-height: 70px;
}

.minh-72px {
  min-height: 72px;
}

.minh-75px {
  min-height: 75px;
}

.minh-80px {
  min-height: 80px;
}

.minh-85px {
  min-height: 85px;
}

.minh-90px {
  min-height: 90px;
}

.input-h46 {
  padding: 0.70rem 0.75rem 0.68rem 0.75rem;
}

.ratio-1x2 {
  --bs-aspect-ratio: 50%;
}

.ratio-1in2 {
  --bs-aspect-ratio: calc(50% - 1.75rem);
}

.ratio-1in3 {
  --bs-aspect-ratio: calc(33.3% - 1.75rem);
}

.shadow-sm {
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1) !important;
}

.pointer-none {
  pointer-events: none;
}

.text-blue {
  color: #324daa;
  color: var(--blue);
}

.text-orange {
  color: #41c9d6;
  color: var(--orange);
}

.text-green {
  color: #08cf08;
  color: var(--green);
}

.text-justify {
  text-align: justify;
}

.bg-blue {
  background-color: #324daa;
  background-color: var(--blue);
}

.bg-lightblue {
  background-color: rgba(32, 188, 239, 0.1);
  background-color: rgba(var(--blue-rgb), 0.1);
}

.bg-orange {
  background-color: #41c9d6;
  background-color: var(--orange);
}

.bg-grey {
  background-color: #edf2f9 !important;
  background-color: var(--grey) !important;
}

.text-grey {
  background-color: #edf2f9;
  background-color: var(--grey);
}

.pb-50px {
  padding-bottom: 50px;
}

.pb-100px {
  padding-bottom: 100px;
}

.m-1px {
  margin: 1px;
}

.m-2px {
  margin: 2px;
}

.m-3px {
  margin: 3px;
}

.m-4px {
  margin: 4px;
}

.my-1px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.my-2px {
  margin-top: 2px;
  margin-bottom: 2px;
}

.my-3px {
  margin-top: 3px;
  margin-bottom: 3px;
}

.my-4px {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-5px {
  margin-top: 5px;
  margin-bottom: 5px;
}

.iti--separate-dial-code {
  width: 100%;
  position: relative;
}

.form-floating .iti--separate-dial-code {
  width: 100%;
  height: 58px;
}

.form-flag-height38 .iti__selected-flag {
  height: 38px;
}

.form-check-input:checked {
  background-color: #324daa;
  background-color: var(--blue);
  border-color: #324daa;
  border-color: var(--blue);
}

.form-check-input:focus,
.form-check-input:active {
  border-color: #324daa;
  border-color: var(--blue);
  box-shadow: 0 0 0 0.25rem rgba(32, 188, 239, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--blue-rgb), 0.25);
}

.btn-blue {
  color: #fff;
  background-color: #324daa;
  background-color: var(--blue);
  border-color: #324daa;
  border-color: var(--blue);
  letter-spacing: 0.3px;
}

.btn-blue:hover {
  opacity: 0.90;
  color: #fff;
  background-color: #324daa;
  background-color: var(--blue);
}

.btn-blue:focus,
.btn-blue:active {
  box-shadow: 0 0 0 0.25rem rgba(32, 188, 239, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--blue-rgb), 0.25);
}

.btn-outline-blue {
  color: #324daa;
  color: var(--blue);
  border-color: #324daa;
  border-color: var(--blue);
  letter-spacing: 0.3px;
}

.btn-outline-blue:hover {
  color: #fff;
  background-color: #324daa;
  background-color: var(--blue);
}

.btn-outline-blue:focus,
.btn-outline-blue:active {
  box-shadow: 0 0 0 0.25rem rgba(32, 188, 239, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--blue-rgb), 0.25);
}

.btn-orange {
  color: #fff;
  background-color: #41c9d6;
  background-color: var(--orange);
  border-color: #41c9d6;
  border-color: var(--orange);
  letter-spacing: 0.3px;
}

.btn-orange:hover {
  opacity: 0.90;
  color: #fff;
}

.btn-orange:focus,
.btn-orange:active {
  box-shadow: 0 0 0 0.25rem rgba(241, 88, 41, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--orange-rgb), 0.25);
}

.btn-outline-orange {
  color: #41c9d6;
  color: var(--orange);
  border-color: #41c9d6;
  border-color: var(--orange);
  letter-spacing: 0.3px;
}

.btn-outline-orange:hover {
  color: #fff;
  background-color: #41c9d6;
  background-color: var(--orange);
}

.btn-outline-orange:focus,
.btn-outline-orange:active {
  box-shadow: 0 0 0 0.25rem rgba(241, 88, 41, 0.25);
  box-shadow: 0 0 0 0.25rem rgba(var(--orange-rgb), 0.25);
}

.img-controller {
  position: absolute;
  width: 100%;
  height: auto;
  top: auto;
  top: 0;
  left: 0;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}

.img-controller button {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  font-weight: bold;
  color: #41c9d6;
  color: var(--orange);
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  line-height: 0.75;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
}

.img-controller button:hover {
  color: #fff;
  background: #41c9d6;
  background: var(--orange);
}

.img-controller label {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5);
  color: #324daa;
  color: var(--blue);
  cursor: pointer;
}

.img-controller label:hover {
  color: #fff;
  background: #324daa;
  background: var(--blue);
}

.img-controller label input {
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 0.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label {
  opacity: 1;
  -webkit-transform: scale(0.85) translateY(-0.8rem) translateX(0.7rem);
  transform: scale(0.85) translateY(-0.8rem) translateX(0.7rem);
  background: #fff;
  height: 28px;
  padding: 2px 2px;
  width: auto;
}

.form-floating>.form-control~label {
  color: #999;
}

.form-floating>.form-select {
  padding-top: 0.625rem;
  padding-bottom: .625rem;
}

.form-floating>.form-select:focus~label,
.form-floating>.form-select:not([value=""]):valid~label {
  opacity: 1;
  -webkit-transform: scale(0.85) translateY(-0.8rem) translateX(0.7rem);
  transform: scale(0.85) translateY(-0.8rem) translateX(0.7rem);
  background: #fff;
  height: 28px;
  padding: 2px 2px;
}

.form-floating>.form-select~label {
  opacity: 1;
  color: #999;
  -webkit-transform: scale(1) translateY(0rem) translateX(0rem);
  transform: scale(1) translateY(0rem) translateX(0rem);
}

.input-group .invalid-feedback {
  order: 8;
}

.input-group-xl>.form-control,
.input-group-xl>button,
.input-group-xl>.form-select {
  padding: 1rem 1.25rem;
  font-size: 1.25rem;
  border-radius: .3rem;
}

.list-group-item {
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.list-group-item.active {
  background-color: #324daa;
  background-color: var(--blue);
  border-color: #324daa;
  border-color: var(--blue);
}

.anchor-blueorange {
  text-decoration: none;
  color: #324daa;
  color: var(--blue);
}

.anchor-blueorange:hover {
  color: #41c9d6;
  color: var(--orange);
}

.anchor-orangeblue {
  text-decoration: none;
  color: #41c9d6;
  color: var(--orange);
}

.anchor-orangeblue:hover {
  color: #324daa;
  color: var(--blue);
}

.text-hoverblue {
  color: inherit;
  text-decoration: none;
}

.text-hoverblue:hover {
  color: #324daa !important;
  color: var(--blue) !important;
}

.text-hoverorange {
  text-decoration: none;
}

.text-hoverorange:hover {
  color: #41c9d6 !important;
  color: var(--orange) !important;
}

.dropdown-menu-center {
  right: auto;
  left: 50% !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: 100% !important;
  transition: 0.250s all;
}

.dropdown-blue .show[data-bs-toggle="dropdown"] {
  color: #324daa;
  color: var(--blue);
}

.dropdown-orange .show[data-bs-toggle="dropdown"] {
  color: #41c9d6;
  color: var(--orange);
}

.nav-tabs .nav-item {
  margin-right: 4px;
}

.nav-common-pills .nav-link {
  background: rgba(0, 0, 0, 0.05);
  color: #324daa;
  color: var(--blue);
  margin-right: 5px;
  position: relative;
}

.nav-common-pills .nav-link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #324daa;
  border-top: 10px solid var(--blue);
  left: calc(50% - 8px);
  top: calc(100% - 10px);
  opacity: 0;
}

.nav-common-pills .nav-link.active {
  background-color: #324daa;
  background-color: var(--blue);
}

.nav-common-pills .nav-link.active::before {
  transition: 350ms;
  transition-delay: 100ms;
  opacity: 1;
  top: calc(100% - 3px);
}

.nav-common-pills .show>.nav-link {
  background-color: #324daa;
  background-color: var(--blue);
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #324daa;
  background-color: var(--blue);
}

/*End | Pre-defined Style*/
/* Start | Header Style */
.navbar-light {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #fff;
}

.navbar-light .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-light .navbar-brand img {
  width: 120px;
}

.navbar-light .navbar-nav .nav-item {
  margin-left: 6px;
}

.navbar-light .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 16px;
  text-align: center;
  padding: .8rem 1rem;
}

.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #41c9d6;
  color: var(--orange);
}

.navbar-light .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-item .nav-link.show,
.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: #324daa;
  color: var(--blue);
}

.navbar-light .navbar-nav .nav-item .nav-link .bi {
  font-size: 22px;
  display: block;
  margin: 4px auto;
}

.left-aside {
  position: -webkit-sticky;
  position: sticky;
  top: 104px;
  z-index: 10;
  min-width: 200px;
}

.left-aside .dropdown-item {
  padding-top: 7px;
  padding-bottom: 6px;
  margin-bottom: 1px;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.left-aside .dropdown-item [class*="bi-"] {
  margin-right: 12px;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
}

.login-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0;
  background-color: #eff6fe;
}

.section-fill {
  min-height: calc(100vh - 68px - 54px);
}

.hero-section {
  padding: 50px 0;
  min-height: calc(55vh - 63.59px);
  overflow: hidden;
  position: relative;
}

.carousel-home .carousel-indicators {
  z-index: 5;
}

.carousel-home .carousel-inner {
  height: inherit;
}

.carousel-home .carousel-inner .carousel-item {
  height: inherit;
  transition: 1s ease;
}

.carousel-home .carousel-inner .carousel-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.carousel-home .carousel-inner .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  /* min-height: 500px; */
}

.carousel-home .carousel-inner .carousel-item .carousel-caption {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-noopacity .carousel-inner {
  height: inherit;
}

.carousel-noopacity .carousel-inner .carousel-item {
  height: inherit;
  transition: 1s ease;
}

.carousel-noopacity .carousel-inner .carousel-item::after {
  display: none;
}

.heading-lines {
  position: relative;
  line-height: 1;
  padding-bottom: 14px;
}

.heading-lines::before,
.heading-lines::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #41c9d6;
  background: var(--orange);
  box-shadow: 0 0 0 1px #fff;
}

.heading-lines::after {
  width: 15px;
  background: #324daa;
  background: var(--blue);
}

.course-item {
  position: relative;
  overflow: hidden;
  border: 1px solid #dbf3fb;
  border-radius: 0.25rem;
}

.course-item:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
  transition: 500ms;
}

.course-item:hover .ratio img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.course-item .ratio {
  overflow: hidden;
}

.course-item .ratio img {
  transition: 1s ease;
}

.course-item .course-tag {
  background-color: rgba(0, 0, 0, 0.65);
  padding: 3px 10px 2px 10px;
  letter-spacing: 1px;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 10px;
  left: 10px;
  box-shadow: 0 0 2px #ccc;
}

.course-item .course-avatar {
  padding: 0px 15px;
  margin-top: -24px;
  position: relative;
  z-index: 2;
}

.course-item .course-avatar img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #324daa;
  background: var(--blue);
  object-fit: cover;
  border: none;
  outline: none;
  padding: 0;
  display: block;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
}

.course-item .course-avatar span {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2px 10px 1px 10px;
  font-weight: 100;
  letter-spacing: 1px;
  border-radius: 30px;
  color: #41c9d6;
  color: var(--orange);
  font-family: 'Roboto', sans-serif;
  box-shadow: 0 0 4px #ccc;
}

.course-item .course-heading {
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-item .course-description {
  overflow: hidden;
  display: -moz-box;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  opacity: 0.75;
}

.owl-arrow-dots .owl-nav {
  position: absolute;
  width: calc(100% + 70px);
  top: calc(50% - 30px);
  left: -35px;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.owl-arrow-dots .owl-nav button.owl-next,
.owl-arrow-dots .owl-nav button.owl-prev {
  width: 30px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  overflow: hidden;
  pointer-events: all;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem;
}

.owl-arrow-dots .owl-nav button.owl-next:hover,
.owl-arrow-dots .owl-nav button.owl-prev:hover {
  background: rgba(0, 0, 0, 0.1);
}

.owl-arrow-dots .owl-nav button.owl-next span,
.owl-arrow-dots .owl-nav button.owl-prev span {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #324daa;
  color: var(--blue);
  -webkit-transform: scale(3);
  transform: scale(3);
  -webkit-transform-origin: center 13px;
  transform-origin: center 13px;
  display: block;
}

.owl-arrow-dots .owl-dots {
  text-align: center;
}

.owl-arrow-dots .owl-dots button.owl-dot {
  width: 15px;
  height: 5px;
  padding: 0;
  background: #324daa;
  background: var(--blue);
  opacity: 0.4;
  border-radius: 2px;
  margin: 0 3px;
}

.owl-arrow-dots .owl-dots button.owl-dot.active {
  opacity: 1;
}

.feedback-item {
  position: relative;
  border: 1px solid #dbf3fb;
  border-radius: 0.25rem;
  overflow: hidden;
}

.feedback-item .feedback-topbox {
  position: relative;
  padding: 80px 20px 60px 20px;
  color: #666;
  min-height: 240px;
}

.feedback-item .feedback-topbox::before {
  content: "“";
  font-size: 100px;
  line-height: 1;
  font-weight: 700;
  font-family: 'Roboto', sans-serif;
  display: inline-flex;
  width: 50px;
  height: 50px;
  top: 10px;
  left: 12px;
  overflow: hidden;
  position: absolute;
  color: #324daa;
  color: var(--blue);
}

.feedback-item .feedback-bottombox {
  position: relative;
  display: block;
  background: url("data:image/svg+xml,<svg fill='%2320bcef' viewBox='0 0 300 150' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'><path d='M-.5,32.5s44-28,78-30,55,7,63,10,53,23,109,25L300,38l1.5,112.5H-.5Z'/></svg>");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  padding: 60px 15px 20px 15px;
}

.feedback-item .feedback-bottombox img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 3px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: -20px;
  margin: 0 auto;
  object-fit: cover;
}

.feedback-item .feedback-bottombox h5 {
  margin: 0;
  padding: 15px 0 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.feedback-item .feedback-bottombox p {
  margin: 0;
  text-align: center;
  color: #fff;
  opacity: 0.95;
  font-size: 15px;
}

.feedback-item .feedback-socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feedback-item .feedback-socials a {
  text-decoration: none;
  color: #fff;
  display: inline-flex;
  margin: 2px 10px;
  font-size: 20px;
}

.feedback-item .feedback-socials a:hover {
  opacity: 0.8;
  transition: 300ms;
}

.course-info {
  position: relative;
  display: flex;
}

.course-info [class*="bi-"] {
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  line-height: 1;
  font-size: 36px;
  margin-right: 14px;
  color: #324daa;
  color: var(--blue);
  opacity: 0.65;
}

.course-info .course-typo {
  display: block;
}

.course-info .course-typo small {
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  letter-spacing: 0.5px;
  font-size: 13px;
  display: block;
  margin-bottom: 3px;
  color: #324daa;
  color: var(--blue);
}

.gredient-orange {
  background: linear-gradient(-45deg, #febf96, #fe7e95);
}

.gredient-blue {
  background: linear-gradient(-45deg, #8fc9f9, #1d8ce5);
}

.gredient-green {
  background: linear-gradient(-45deg, #82d9d1, #1bac96);
}

.gredient-purple {
  background: linear-gradient(-45deg, #d88aff, #9b56ff);
}

.gredient-gold {
  background: linear-gradient(-45deg, #f0cd81, #ca8b15);
}

.gredient-lightgreen {
  background: linear-gradient(-45deg, #69df83, #588b2a);
}

.gredient-lightpurple {
  background: linear-gradient(-45deg, #c386aa, #d40ac4);
}

.gredient-lightred {
  background: linear-gradient(-45deg, #f58282, #df2f38);
}

.star {
  display: inline-block;
  color: #FFC835;
  font-family: 'Roboto', sans-serif;
  vertical-align: middle;
  font-size: inherit;
  line-height: 1;
  letter-spacing: 2px;
  position: relative;
}

.star::before {
  content: '\2605\2605\2605\2605\2605';
  font-family: inherit;
  color: #ccc;
}

.star::after {
  font-family: inherit;
  position: absolute;
  left: 0;
  top: 0;
}

.star.star-1::after {
  content: '\2605';
}

.star.star-2::after {
  content: '\2605\2605';
}

.star.star-3::after {
  content: '\2605\2605\2605';
}

.star.star-4::after {
  content: '\2605\2605\2605\2605';
}

.star.star-5::after {
  content: '\2605\2605\2605\2605\2605';
}

.accordion-item .accordion-button {
  background-color: #f9f9f9;
}

.accordion-item .accordion-button:not(.collapsed) {
  color: #41c9d6;
  color: var(--orange);
  background-color: #e8f8fd;
}

.accordion-item .accordion-button:hover {
  background-color: #f2f2f2;
}

.table> :not(:first-child) {
  border-top: 1px solid rgba(32, 188, 239, 0.2);
}

.table> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: rgb(32 188 239 / 9%);
}

.table tr {
  border-bottom: 1px solid rgba(32, 188, 239, 0.2);
}

.table-lasttd-center th:last-child,
.table-lasttd-center td:last-child {
  text-align: center;
}

.table-lasttd-end th:last-child,
.table-lasttd-end td:last-child {
  text-align: right;
}

.table-imgbox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e9ef;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  text-decoration: none;
}

.table-imgbox.table-img40 {
  width: 40px;
  height: 40px;
}

.table-imgbox.table-img50 {
  width: 50px;
  height: 50px;
}

.table-imgbox.table-img60 {
  width: 60px;
  height: 60px;
}

.table-imgbox.table-img80 {
  width: 80px;
  height: 80px;
}

.table-imgbox.table-img100 {
  width: 100px;
  height: 100px;
}

.table-imgbox img {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Start | Admin Style */
.admin-header {
  top: 0;
  left: 250px;
  position: fixed;
  z-index: 100;
  width: calc(100% - 250px);
  height: 54px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.1);
}

.admin-header .admin-profile {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 0;
  background: transparent;
  outline: none;
  border: none;
  height: 50px;
}

.admin-header .admin-profile img {
  width: 36px;
  height: 36px;
  border-radius: 40px;
  display: block;
  object-fit: cover;
}

.admin-header .admin-profile h5 {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 1;
}

.admin-header .admin-profile p {
  margin: 3px 0 0 0;
  font-size: 12px;
  line-height: 12px;
  opacity: 0.75;
}

.admin-aside {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 58px 0.3rem rgba(0, 0, 0, 0.1);
  background: #fff;
}

.admin-aside .admin-logobox {
  flex-grow: 0;
  padding: 15px 10px 15px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.admin-aside .admin-logobox .admin-brandbox {
  text-decoration: none;
}

.admin-aside .admin-logobox .admin-brandbox img {
  height: 83px;
}

.admin-aside .admin-logobox button {
  display: none;
}

.admin-aside .admin-logobox hr {
  position: absolute;
  width: calc(100% - 20px);
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  opacity: 0.1;
}

.admin-aside .admin-navbox {
  flex-grow: 1;
  padding: 15px 10px 10px 10px;
  overflow: hidden;
  overflow-y: auto;
}

.admin-aside .admin-navbox .accordion-item {
  border-radius: 0;
  border: none;
  background: transparent;
}

.admin-aside .admin-navbox .accordion-item .main-link {
  font-family: 'Roboto', sans-serif;
  display: flex;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  padding: 9px 10px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 15px;
  color: #444;
  position: relative;
}

.admin-aside .admin-navbox .accordion-item .main-link::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 1px solid currentColor;
  border-right: 1px solid currentColor;
  top: calc(50% - 4px);
  right: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  transition: 300ms;
}

.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded]::before {
  opacity: 1;
}

.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded="true"]::before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.admin-aside .admin-navbox .accordion-item .main-link:hover {
  background: rgba(32, 188, 239, 0.2);
  background: rgba(var(--blue-rgb), 0.2);
}

.admin-aside .admin-navbox .accordion-item .main-link.active,
.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded="true"] {
  background: #324daa;
  background: var(--blue);
  color: #fff;
}

.admin-aside .admin-navbox .accordion-item .main-link.active [class*="bi-"],
.admin-aside .admin-navbox .accordion-item .main-link[aria-expanded="true"] [class*="bi-"] {
  color: inherit;
}

.admin-aside .admin-navbox .accordion-item .main-link [class*="bi-"] {
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 1;
  margin-right: 11px;
  color: #41c9d6;
  color: var(--orange);
  opacity: 0.90;
}

.admin-aside .admin-navbox .accordion-collapse {
  padding-left: 29px;
  font-size: 15px;
}

.admin-aside .admin-navbox .accordion-collapse .sub-link {
  display: block;
  text-decoration: none;
  padding: 5px 10px;
  margin-bottom: 4px;
  border-radius: 3px;
  color: #444;
  font-weight: 400;
  position: relative;
  background: rgba(32, 188, 239, 0.05);
  background: rgba(var(--blue-rgb), 0.05);
  border: 1px solid rgba(32, 188, 239, 0.05);
  border: 1px solid rgba(var(--blue-rgb), 0.05);
}

.admin-aside .admin-navbox .accordion-collapse .sub-link:last-child {
  margin-bottom: 15px;
}

.admin-aside .admin-navbox .accordion-collapse .sub-link:hover {
  background: rgba(32, 188, 239, 0.2);
  background: rgba(var(--blue-rgb), 0.2);
}

.admin-aside .admin-navbox .accordion-collapse .sub-link.active,
.admin-aside .admin-navbox .accordion-collapse .sub-link:active {
  background: #324daa;
  background: var(--blue);
  color: #fff;
}

.admin-aside .admin-copyrightsbox {
  flex-grow: 0;
  padding: 3px 10px 2px 10px;
  font-size: 11px;
  color: #999;
}

.admin-wrapper {
  padding-top: 74px;
  padding-bottom: 40px;
  padding-left: 260px;
  padding-right: 10px;
  background: #edf2f9;
  min-height: 100vh;
}

.dasboard-tiles {
  position: relative;
  text-decoration: none;
}

.dasboard-tiles [class*="bi-"] {
  position: absolute;
  font-size: 70px;
  top: 50%;
  right: 20px;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  opacity: 0.15;
  z-index: 0;
}

.toast-end .toast {
  letter-spacing: 0.5px;
  overflow: hidden;
}

.toast-end .toast.show {
  opacity: 1;
  -webkit-animation: toastanim 0.350s linear forwards;
  animation: toastanim 0.350s linear forwards;
}

.toast-end .toast.hide {
  display: block;
  opacity: 1;
  -webkit-animation: toastanimreverse 0.350s linear forwards;
  animation: toastanimreverse 0.350s linear forwards;
}

.toast-end .toast .bg-warning .toast-body,
.toast-end .toast .bg-light .toast-body {
  color: #000 !important;
}

.toast-end .toast .bg-warning .btn-close,
.toast-end .toast .bg-light .btn-close {
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-filter: invert(0);
  filter: invert(0);
}

.toast-end .toast .bg-warning .btn-close:hover,
.toast-end .toast .bg-light .btn-close:hover {
  -webkit-filter: invert(1);
  filter: invert(1);
  background-color: rgba(4, 255, 255, 0.6);
}

.toast-end .toast .btn-close {
  background-color: rgba(255, 255, 255, 0.05);
  -webkit-filter: invert(1);
  filter: invert(1);
  opacity: 1;
  transition: 300ms;
}

.toast-end .toast .btn-close:hover {
  background-color: rgba(4, 255, 255, 0.6);
}

@-webkit-keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes toastanim {
  from {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }

  to {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
  }
}

@keyframes toastanimreverse {
  from {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  to {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
  }
}

/* End | Admin Style */
.table:hover:hover .link-effect::before {
  width: 50px;
}

.table:hover:hover .link-effect:hover::before {
  width: 100%;
}

.link-effect {
  position: relative;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  transition: 500ms;
}

.link-effect:hover {
  color: #41c9d6;
  color: var(--orange);
}

.link-effect::before {
  content: '';
  width: 0;
  height: 1px;
  left: 0;
  bottom: 1px;
  display: block;
  position: absolute;
  background-color: currentColor;
  transition: 400ms ease;
  opacity: 0.4;
}

.modal-backdrop.show {
  opacity: .7;
}

.fc .fc-toolbar-title {
  font-family: 'Roboto', sans-serif;
}

.fc a {
  text-decoration: none;
  color: inherit;
}

.fc .fc-daygrid-dot-event .fc-event-title {
  font-family: 'Roboto', sans-serif;
}

.pagination-sm .page-item.previous.disabled,
.pagination-sm .page-item.next.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.pagination-sm .page-item.previous .page-link,
.pagination-sm .page-item.next .page-link {
  width: 74px;
  font-weight: 500;
}

.pagination-sm .page-item.active .page-link,
.pagination-sm .page-item.active .page-link:hover {
  background-color: #324daa;
  background-color: var(--blue);
  border-color: #324daa;
  border-color: var(--blue);
  color: #fff;
}

.pagination-sm .page-item:hover .page-link {
  background-color: rgba(32, 188, 239, 0.15);
  background-color: rgba(var(--blue-rgb), 0.15);
  border-color: rgba(32, 188, 239, 0.15);
  border-color: rgba(var(--blue-rgb), 0.15);
}

.pagination-sm .page-item .page-link {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid #dee2e6;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #212529;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1200;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
}

.loader.active {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.loader .loader-zoomer {
  width: 150px;
  height: 150px;
  -webkit-animation: scaller 2s linear infinite;
  animation: scaller 2s linear infinite;
}

@-webkit-keyframes scaller {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaller {
  50% {
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.page-item:not(:first-child) .page-link {
  margin-left: 2px;
}

.page-link:hover {
  color: #000;
  background-color: rgba(32, 188, 239, 0.15);
  background-color: rgba(var(--blue-rgb), 0.15);
  border-color: rgba(32, 188, 239, 0.15);
  border-color: rgba(var(--blue-rgb), 0.15);
}



/* ========================================== */
/* Start | Responsive Style */
/* ========================================== */
.display-7 {
  font-size: calc(1.375rem + 1.25vw);
  line-height: 1.35;
}

.display-8 {
  font-size: calc(0.9rem + 1vw);
  line-height: 1.40;
}

@media (min-width: 1201px) {

  /* .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1360px;
  } */
  .display-7 {
    font-size: 2rem;
  }

  .display-8 {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) {
  .menu-open .admin-aside {
    left: -300px;
  }

  .menu-open .admin-header {
    left: 0px;
    width: 100%;
  }

  .menu-open .admin-wrapper {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: 576px) {
  .w-sm-50 {
    width: 50%;
  }

  .w-sm-75 {
    width: 75%;
  }

  .w-sm-80 {
    width: 80%;
  }
}

@media (max-width: 1560px) {
  .admin-wrapper {
    padding-bottom: 40px;
  }
}

@media (max-width: 1199.9px) {

  /* .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 100%; 
  }*/
  .navbar .navbar-nav {
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .navbar .navbar-nav .nav-item {
    width: 33.33%;
    margin: 0 -1px -1px 0;
    border: 1px dashed #ccc;
    text-align: center;
  }

  .navbar .navbar-nav .nav-item:last-child {
    padding-top: 25px;
    padding-bottom: 5px;
    width: 100%;
    border: none;
  }

  .navbar .navbar-nav .nav-item:last-child .btn {
    margin-bottom: 5px;
    min-width: 120px;
  }

  .ratio-1in3 {
    --bs-aspect-ratio: calc(95% - 1.75rem);
  }

}

@media (max-width: 991.9px) {
  .admin-header {
    left: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .admin-aside {
    left: -260px;
    transition: 350ms ease;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }

  .admin-aside .admin-logobox .admin-brandbox img {
    height: 58px;
  }

  .admin-aside .admin-logobox button {
    display: flex;
  }

  .admin-aside .admin-copyrightsbox {
    flex-grow: 0;
    font-size: 11px;
  }

  .admin-wrapper {
    padding-left: 0px;
    padding-right: 0px;
    transition: 350ms;
  }

  .menu-open {
    overflow: hidden;
  }

  .menu-open .admin-aside {
    left: 0px;
  }

  .menu-open .admin-header {
    pointer-events: none;
  }

  .menu-open .admin-header,
  .menu-open .admin-wrapper {
    opacity: 0.075;
  }

  .form-searchrow .col,
  .form-searchrow .col-auto {
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 575px) {
  .navbar .navbar-brand img {
    width: 86.5px;
  }

  .navbar .navbar-nav .nav-item {
    width: 50%;
  }

  .carousel-home .carousel-indicators {
    margin-bottom: 0;
  }

  .form-searchrow .col,
  .form-searchrow .col-auto {
    flex: 1 0 100%;
    max-width: 100%;
  }
}

/* ========================================== */
/* End | Responsive Style */
/* ========================================== */
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.custom-table thead th {
  font-family: 'Roboto', sans-serif;
  border: none;
  padding: 4px 14px;
  color: #f2f6f7;
  background-color: #3e8ea9;
  font-weight: normal;
  margin-bottom: 4px;
}

.custom-table thead th:first-child {
  border-radius: 6px 0 0 6px;
}

.custom-table thead th:last-child {
  border-radius: 0 6px 6px 0;
}

.custom-table tbody tr {
  box-shadow: 0 0 8px 0px #eceff1;
  border-radius: 6px;
  overflow: hidden;
}

.custom-table tbody tr:hover {
  box-shadow: 0 0 0 1px #d5e6f1;
}

.custom-table tbody tr:hover th,
.custom-table tbody tr:hover td {
  background: #f9f9f9;
}

.custom-table tbody tr th,
.custom-table tbody tr td {
  border: none;
  padding: 10px 14px;
  font-size: 14px;
  background: #fff;
}

.custom-table tbody tr th:first-child,
.custom-table tbody tr td:first-child {
  border-radius: 6px 0 0 6px;
}

.custom-table tbody tr th:last-child,
.custom-table tbody tr td:last-child {
  border-radius: 0 6px 6px 0;
}

/*Start | For Time-Table & Code Input */
.schedule_event {
  background-color: #41c9d6;
  background-color: var(--orange);
}

.schedule_course {
  background-color: #324daa;
  background-color: var(--blue);
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-event-title,
.fc-event-time {
  color: #fff;
}

.schedule_event,
.schedule_course {
  padding: 2px 5px !important;
  cursor: pointer;
  margin-bottom: 3px;
}

.schedule_event:hover,
.schedule_course:hover {
  background-color: #1a252f !important;
}

.iti__selected-flag {
  max-height: 58px;
}

input[type="search"]::-webkit-search-decoration:hover,
input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

/*Start | For Time-Table & Code Input */
/*
  |--------------------------------------------------------------------------
  | Start | Customize Zoom CSS
  |--------------------------------------------------------------------------
*/
.zm-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1500;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
}

.zm-modal.active {
  opacity: 1;
  visibility: visible;
}

.zm-modal .btn-danger {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1700;
}

[x-placement="right-start"],
[x-placement="left-start"],
[x-placement="bottom"],
[x-placement="bottom-end"],
[x-placement="bottom-start"],
[x-placement="top-start"],
[x-placement="top-end"],
[x-placement="top"] {
  z-index: 1550 !important;
}

.MuiDialog-root {
  z-index: 1555 !important;
}

#transition-popper {
  z-index: 1501 !important;
}

body.zoom-open {
  overflow: hidden;
}

#meetingSDKElement {
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

#meetingSDKElement .react-draggable:nth-child(1) {
  width: 100%;
  -webkit-transform: translate(0px, 0px) !important;
  transform: translate(0px, 0px) !important;
}

#meetingSDKElement .react-draggable:nth-child(1)>div:nth-child(1)>div:nth-child(1) {
  /* width: 100%; */
  min-width: 100%;
}

.zoom-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}


.MuiPaper-rounded {
  border-radius: 0px !important;
}

.MuiBox-root.full-view {
  width: 100vw;
  height: calc(100vh - 34px - 42px) !important;
}

.MuiPaper-root.in-sharing.MuiPaper-elevation1.MuiPaper-rounded {
  width: 100vw !important;
}

.MuiBox-root.full-view .in-sharing:nth-child(2) {
  width: 200px !important;
}

.MuiBox-root.full-view>div:nth-child(2) {
  /* width: 100%!important; */
  margin: 0 auto;
}


@media screen and (max-width: 1400px) {
  .MuiBox-root.full-view .in-sharing:nth-child(2) {
    width: 150px !important;
  }
}

@media screen and (max-width: 1060px) {
  .MuiBox-root.full-view .in-sharing:nth-child(2) {
    width: 100px !important;
  }
}

#menu-list-icon-arrow-down {
  height: 300px;
  overflow: auto;
}

#root~iframe[style*="2147483647"] {
  z-index: -1000 !important;
  display: none;
}

/*
  |--------------------------------------------------------------------------
  | End | Customize Zoom CSS
  |--------------------------------------------------------------------------
*/


/*Start | Star Rating*/
.star-rating {
  display: inline-block;
  color: #ccc;
  font-family: 'Roboto', sans-serif;
  vertical-align: top;
  position: relative;
}

.star-rating::before {
  content: '\2605\2605\2605\2605\2605';
  font-family: inherit;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0px;
  text-shadow: 0 1px 0px #000;
}

.star-rating span {
  display: block;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  white-space: nowrap;
  transition: 400ms;
}

.star-rating span::before {
  content: '\2605\2605\2605\2605\2605';
  position: relative;
  font-family: inherit;
  color: #ff9f43;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0px;
}


.form-fileview {
  position: relative;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.form-fileview .form-image {
  position: relative;
  z-index: 3;
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  display: inline-flex;
  margin: 5px 5px 0 0;
  box-shadow: 0 0 0 1px #ced4da;
}

.form-fileview .form-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.form-fileview .form-image button {
  position: absolute;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(243, 99, 99, 0.85);
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  top: 0px;
  right: 0px;
}

.form-fileview .form-image button:hover {
  background-color: #f04242;
}

.action-button-loading {
  pointer-events: none;
  opacity: 0.65;
}

.action-button-loading>div {
  display: block !important;
}

.action-button-loading>i {
  display: none;
}


.disable-form {
  pointer-events: none;
}

.disable-form .form-control,
.disable-form .form-select {
  padding-left: 0;
  border-radius: 0;
  border: 1px solid transparent;
  box-shadow: none;
  /* border-bottom: 1px solid #eee; */
  resize: none;
}

.disable-form .form-control:focus,
.disable-form .form-select:focus {
  color: #324daa;
  color: var(--blue);
  border-bottom: 1px solid #324daa;
  border-bottom: 1px solid var(--blue);
}

.disable-form .form-select {
  background-image: none;
}

.disable-form .showon-active {
  display: none;
}

.disable-form sup {
  display: none;
}

.disable-form label {
  opacity: .7;
}

.disable-form [data-render="0"],
.disable-form [data-render="1"] input:checked {
  display: none;
  margin-right: 0;
}

.disable-form [data-render="1"] {
  padding-left: 0;
}

ul {
  list-style: none;
}

button {
  font-family: inherit;
  border: none;
  background: transparent;
  cursor: pointer;
}

.weather-card-info {
  display: flex;
  grid-gap: 5px;
  align-items: center;
  height: 30px;
}

.weather-card-info-icon {
  font-size: 24px;
  padding-top: 5px;
  -webkit-animation: weather_move 1s infinite alternate ease-in-out;
          animation: weather_move 1s infinite alternate ease-in-out;
}

@-webkit-keyframes weather_move {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

@keyframes weather_move {
  to {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }
}

.react-datepicker__month-read-view {
  position: absolute !important;
}

.adb-mt-19 {
  margin-top: 19px !important;
}

.dataTables_length {
  width: -webkit-max-content;
  width: max-content;
}

.bg-light-info {
  background: #41c9d642;
}

.bg-light-blue {
  background: #324daa2e;
}

.iti__flag-container {
  height: 40px;
}

button.action-btn {
  height: 24px;
  width: 24px;
  font-size: 12px !important;
  padding: 0 !important;
}

.was-validated .react-select.is-invalid div {
  border-color: #dc3545 !important;
  border-radius: 5px !important;
}
.was-validated .react-select.is-invalid div:hover {
  border-color: #dc3545 !important;
}
.was-validated .react-select.is-valid div {
  border-color: #198754 !important;
  border-radius: 5px !important;
}
.was-validated .react-select.is-valid div:hover {
  border-color: #198754 !important;
}
.was-validated .react-select div:hover {
  border-color: rgba(0, 1, 0, ) !important;
}
.was-validated div:has(.invalid-custom-feedback) .invalid-feedback {
  display: none;
}

.no-validate-icon {
  background-image: none !important;
}

.custom-css div.react-datepicker__year-option > a.react-datepicker__navigation--years-upcoming, .custom-css div.react-datepicker__year-option > a.react-datepicker__navigation--years-previous {
  display: none;
}

.custom-css div.react-datepicker__year-option:has(a.react-datepicker__navigation--years-upcoming)::after {
  content: "\f286";
}

.custom-css div.react-datepicker__year-option:has(a.react-datepicker__navigation--years-previous)::after {
  content: "\F282";
}

.custom-css div.react-datepicker__year-option:has(a.react-datepicker__navigation--years-upcoming)::after, .custom-css div.react-datepicker__year-option:has(a.react-datepicker__navigation--years-previous)::after {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
}

.icon-white:hover img, .icon-white:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.icon-black:hover img {
  -webkit-filter: brightness(0%);
          filter: brightness(0%);
}

.dataTables_paginate .paginate_button:hover{
  background-color: rgba(32, 188, 239, 0.2);
  background-color: rgba(var(--blue-rgb), 0.2);
}

.react-datepicker__close-icon {
  right: 25px !important;
}
*,
::after,
::before {
  box-sizing: border-box;
}

/*Start | Datatable layout modified*/
.dataTable {
  padding: 1px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 6px;
  transition: 300ms;
}

.dataTable tbody tr:hover {
  box-shadow: 0 0 1px 1px #eee !important;
  background-color: #eee !important;
  color: #000000;
}

.dataTable tbody tr:hover td {
  color: #000000 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
  font-size: 0;
}

.dataTable.table>:not(:first-child) {
  border-top: 2px solid rgba(112, 118, 185, 0.35);
}

.dataTable tr th {
  font-size: 13.5px;
  letter-spacing: 0.25px;
  border: none;
  padding: 8px 14px !important;
  font-weight: 700 !important;
  color: #ffffff !important;
  background-color: #324daa !important;
  margin-bottom: 4px;
  text-transform: uppercase;
  position: relative;
}

.dataTable tr td {
  font-size: 14.5px !important;
  color: #6c757d !important;
  transition: 300ms;
}

.dataTable.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}

.dataTables_wrapper .dataTables_length select,
.dataTables_wrapper .dataTables_length input,
.dataTables_wrapper .dataTables_filter select,
.dataTables_wrapper .dataTables_filter input {
  display: block;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.dataTables_wrapper .dataTables_length select:focus,
.dataTables_wrapper .dataTables_length input:focus,
.dataTables_wrapper .dataTables_filter select:focus,
.dataTables_wrapper .dataTables_filter input:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.dataTables_wrapper .dataTables_filter {
  display: inline-block;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter label {
  width: 100%;
  max-width: 280px;
}

.dataTables_wrapper .dataTables_filter label input {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 10px 7px;
  padding-left: 33px;
}

.dataTables_wrapper .dataTables_length label {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}

.dataTables_wrapper .dataTables_length label select {
  margin: 0 6px;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .22rem center;
  background-size: 14px 10px;
  padding-right: 20px;
  text-align-last: center;
  -moz-text-align-last: center;
}

.dataTables_wrapper .table {
  margin-bottom: 0;
  width: 100%;
}

.dataTables_wrapper .table thead .sorting {
  padding-right: 14px;
  position: relative;
}

.dataTables_wrapper .table thead .sorting::before,
.dataTables_wrapper .table thead .sorting::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  right: 4px;
  margin-top: -6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid currentColor;
  opacity: 0.2;
}

.dataTables_wrapper .table thead .sorting::after {
  margin-top: 2px;
  border-bottom: 4px solid transparent;
  border-top: 6px solid currentColor;
}

.dataTables_wrapper .table thead .sorting.sorting_asc::before {
  opacity: 1;
  border-bottom-color: #41c9d6;
  ;
}

.dataTables_wrapper .table thead .sorting.sorting_desc::after {
  opacity: 1;
  border-top-color: #41c9d6;
  ;
}

.dataTables_wrapper .dataTables_info {
  font-size: 14px;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 7px;
  height: 30px;
  min-width: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  font-weight: bold;
  border: 1px solid transparent;
  margin-left: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  height: 30px;
  min-width: 30px;
  text-decoration: none;
  display: inline-flex;
  border: 1px solid #dee2e6;
  margin-left: 2px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  font-size: 14px;
  color: #212529;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  font-weight: 500;
  padding: 0 7px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background-color: #324daa;
  ;
  border-color: #324daa;
  ;
  color: #fff;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background-color: rgba(#324daa, 0.15);
  border-color: rgba(#324daa, 0.15);
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  width: 100%;
  height: calc(100% - 43px);
  top: 43px;
  left: 0;
  background: rgba(255, 255, 255, 0.85);
  /* padding-top: 51px; */
  text-align: center;
  color: var(--blue);
  font-weight: 600;
  letter-spacing: 1px;
}

.dataTables_wrapper .dataTables_empty {
  text-align: center !important;
  color: red;
  /* padding: 50px 10px !important; */
  opacity: 0.75;
}

@media (max-width: 991.90px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dt-custom-filter {
    margin-bottom: 8px;
  }

  .dataTables_wrapper .dataTables_length {
    float: left;
  }

  .dataTables_wrapper .dataTables_filter label {
    max-width: 100%;
  }

  .dataTables_paginate {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

@media (max-width: 420px) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    font-size: 8px;
    margin-left: 1px;
  }
}

.dataTables_processing::before {
  content: '';
  position: absolute;
  border-radius: 20px;
  top: 2px;
  left: 0;
  background: linear-gradient(to right, #edf2f9, #6477ba, #576fc7, #324daa) !important;
  width: 0;
  height: 3px;
  z-index: 9999;
  box-shadow: 0 0px 0 1px #fff;
  -webkit-animation: tableloadingBar 4s linear infinite;
          animation: tableloadingBar 4s linear infinite;
}

@-webkit-keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes tableloadingBar {
  0% {
    width: 0%;
  }

  50% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.dt-buttons {
  position: relative;
}

.dt-button-collection {
  position: absolute;
  width: 200px;
  top: 40px;
  left: 0;
  margin-top: 0 !important;
  background: #fff;
  box-shadow: 0 5px 14px #ccc;
  z-index: 10;
  padding: 15px;
  border-radius: 4px;
}

.dt-button-collection .dt-button {
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 15px;
  margin-bottom: 5px;
  font-size: 14px;
}

.dt-button-collection .dt-button.active,
.export-btn:hover {
  background: var(--blue);
  color: #fff;
  border: 1px solid var(--blue);
}

.dt-button-collection .dt-button:last-child {
  margin-bottom: 0;
}

.dt-button-collection-title {
  color: var(--bs-primary);
  font-size: 13px;
  text-align: left;
  margin-bottom: 8px;
}

table.dt-rowReorder-float {
  opacity:.1
}

div.dt-rowReorder-float-parent {
  table-layout:fixed;
  /* outline:2px solid #00000045; */
  outline-offset:-2px;
  z-index:2001;
  position:absolute !important;
  overflow:hidden
}

tr.dt-rowReorder-moving {
  outline:2px solid #324daa;
  outline-offset:-2px
}

body.dt-rowReorder-noOverflow {
  overflow-x:hidden
}

table.dataTable td.reorder {
  text-align:center;
  cursor:move
}

/* .dataTable tr td.drag {
  background-color: #576fc71f;
} */

.dataTable tr td.drag:hover {
  cursor: move;
}

/*End | Datatable layout modified*/
